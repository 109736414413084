.progression-bar{
    margin: 0px;
    height: 20px;
    min-width: 100px;
    width: 100%;
    background: var(--mid-white);
    border-radius: 10px;
    overflow: hidden;
}
.progression-bar.small{
    max-width: 200px;
}
.progression-bar-progression{
    background: var(--gradient);
    background-size: 500%;
    border-radius: 10px;
    height: 20px;
    text-align: center;
    transition: 2s;
    animation: gradient-infinite 10s linear infinite;
} 