@keyframes player-countdown-after{
    0% {
        transform: scale(0) rotate(0);
        border-radius: 20px;
    }
    25.001%{
        transform: scale(6) rotate(20deg);
        border-radius: 50%;
    }
    50.001%{
        transform: scale(0) rotate(-60deg);
        border-radius: 20px;
    }
    75.001%{
        transform: scale(10) rotate(80deg);
        border-radius: 50%;
    }
    90% {
        transform: scale(0) rotate(-200deg);
        border-radius: 20px;
    }
}
@keyframes player-countdown-text{
    0% {
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    20.001%{
        opacity: 1;
        transform: scale(2) rotate(10deg);
    }
    25.001%{
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    45.001%{
        opacity: 1;
        transform: scale(3) rotate(-30deg);
    }
    50.002%{
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    70.001%{
        opacity: 1;
        transform: scale(5) rotate(20deg);
    }
    75.002%{
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    100% {
        opacity: 0;
        transform: scale(0) rotate(0);
    }
}
@keyframes player-countdown-avatar{
    0% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(-50%, -50%) scale(2) rotate(77deg);
    }
}

.player-countdown-label{
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.player-countdown{
    position: relative;
    z-index: 10;
    border-radius: 10px;
    color: black;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.player-countdown:after{
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(0);
    background: white;
    opacity: 1;
    animation: player-countdown-after 4s ease;
}
.player-countdown-text{
    animation: player-countdown-text 4s linear;
    opacity: 0;
}

.player-countdown-avatar{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: var(--box-shadow);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 0;
    transition: .1s;
}
.player-countdown-avatar.active{
    animation: player-countdown-avatar 1s linear;
    opacity: 1;
}
