.pop-up{
    z-index: 11;
    pointer-events: none;
    background: var(--pop-up);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow);
    animation: reveal .5s ease-out;
}
.pop-up-banner{
    background: var(--cgr);
    background-size: 300%;
    color: white;
    width: calc(100% - 40px);
    padding: 20px;
}
.pop-up.large .pop-up-msg{
    max-width: 100%;
    min-width: 80vw;
}
.pop-up-btn{
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    top: 30px;
    right: 30px;
    font-weight: 900;
    transition: .5s;
}
.pop-up-btn:hover{
    background: black;
    color: white;
}
.pop-up-exit{
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;
    width: 100%;
    height: 100%;
}

.pop-up-msg{
    pointer-events: all;
    max-width: 500px;
    min-width: 200px;
    margin: 20px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: white;
    max-height: calc(100vh - 60px);
    max-height: calc(100svh - 60px);
    animation: slide-up .3s ease-out;
}
.pop-up-content{
    padding: 20px;
    background: white;
    mask-image: linear-gradient(to top, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);
    overflow-y: scroll;
}
.pop-up-controls{
    padding: 20px 20px 0 20px;
    background: white;
}

.pop-up-msg::-webkit-scrollbar{
    height: 5px;
    border-radius: 10px;
    background: none;
}
.pop-up-msg::-webkit-scrollbar-thumb{
    background: var(--light-white);
}