@media screen and (max-width: 699px) {
    .product-list{
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }
    .product-form{
        position: initial;
        max-width: 100%;
    }
    .product-row{
        flex-direction: column;
    }
    .filter-btn{
        z-index: 10;
        position: fixed;
        bottom: 100px;
        left: 50vw;
        transform: translateX(-50%);
    }
}
@media screen and (min-width: 700px) {
    .product-list{
        grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
    .product-form{
        position: initial;
        max-width: 100%;
    }
    .product-column{
        width: 100%;
    }
    .product-row{
        flex-direction: column;
    }
}
@media screen and (min-width: 1000px) {
    .product-list{
        grid-template-columns: repeat(3, minmax(200px, 1fr));
    }
    .product-form{
        position: sticky;
        top: 110px;
        width: 50%;
        max-width: 500px;
    }
    .product-column{
        width: 50%;
    }
    .product-row{
        flex-direction: row;
    }
    .review-list{
        min-width: 200vw;
    }
}

@keyframes product-row{
    0% {
        transform: translateY(-100vh);
    }
    100% {
        transform: translateY(0);
    }
}

.filter-price-price{
    font-size: 30px;
    font-weight: 400;
}
.login-overlay{
    position: relative;
    margin: -10px;
    padding: 10px;
}
.login-overlay-content{
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.login-overlay.active .login-overlay-content{
    display: flex;
}
.login-overlay.active::after{
    position: absolute;
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
}
.filter{
    z-index: 0;
    color: black;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    max-width: 600px;
    gap: 10px;
    padding: 20px;
    border-radius: 30px;
    border: 1px solid var(--mid-white);
    box-shadow: var(--box-shadow);
    background: white;
}
.filter-price{
    border-top: 1px solid var(--mid-white);
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: var(--grey-text);
    padding-top: 10px;
}
.filter-player-count{
    width: 170px;
    user-select: none;
    font-size: 110px;
    font-weight: 900;
    background: var(--gradient);
    background-size: 500%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-decoration: none;
    animation: gradient-infinite 10s linear infinite;
}
.product-item.empty{
    background: none;
    width: 100%;
    border-radius: 0;
    justify-content: center;
    align-items: center;
}
.product-badge{
    position: absolute;
    z-index: 2;
    left: 20px;
    top: 20px;
    width: 45px;
    color: white;
}
.product-age{
    border: 1.5px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 22px;
    height: 22px;
    font-size: 11px;
    color: black;
}
.product-age.badge{
    border: 1.5px solid white;
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px;
    color: white;
}
.product-item-img{
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .5s;
    filter: brightness(.8);
}
.product-item-label{
    position: relative;
    z-index: 1;
    top: 1;
    padding: 20px;
    flex-grow: 1;
    width: auto;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-end;
    align-items: flex-start;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 10%, rgb(0, 0, 0, 0) 30%, rgb(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.7));
    color: white;
}
.product-item-icons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}
.product-price{
    font-weight: 400;
}

.product-row{
    position: relative;
    display: flex;
    justify-content: stretch;
    gap: 40px;
    animation: product-row .5s ease;
}
.product-column{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 40px;
}
.product-img{
    width: 100%;
    height: 40vh;
    max-height: 50vh;
    object-fit: cover;
    background: white;
    flex-grow: 1;
    border-radius: 20px;
}
.product-form{
    min-width: 30%;
    height: fit-content;
    padding: 20px;
    border-radius: 20px;
    background: white;
    color: black;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--light-white);
}
.product-info{
    min-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: fit-content;
}
.product-explanation{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
    gap: 100px;
}
.product-explanation-img{
    height: 70px;
    width: 70px;
}
.product-explanation-column{
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 20px;
    max-width: 700px;
}

.filter-info-list{
    width: 100%;
    display: flex;
    gap: 20px;
    margin: 20px 0;
    flex-direction: column;
    color: black;
}
.filter-info-row{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.filter-info-icon{
    min-width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    max-height: 70px;
}
.filter-info-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    max-width: 40px;
}
.filter-control{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.filter-btn{
    margin: 0;
    padding: 10px 15px;
    background: var(--mid-black);
    border: none;
    height: 40px;
    border-radius: 20px;
    color: white;
    font-family : inherit;
    font-weight: 500;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    transition: .5s;
}
.filter-btn.active .icon{
    transform: rotate(180deg);
}
.filter-btn .icon{
    transition: .5s;
}

.review-container{
    position: relative;
    width: 100%;
    height: 500px;
    overflow-x: hidden;
}
.review-list{
    position: absolute;
    left: 0;
    padding: 0 20px;
    width: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    animation: review-carousel 30s linear infinite;
}
@keyframes review-carousel{
    0%{
        transform: translateX(0%) translateZ(0);
    }
    100%{
        transform: translateX(-50%) translateZ(0);
    }
    
}
.review-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    gap: 5px;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
    max-width: 200px;
    min-width: 200px;
    max-height: 400px;
    box-shadow: var(--box-shadow);
    transform: translateZ(0);
    background: white;
}
.review-avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.review-star{
    color: transparent;
    min-width: 20px;
    height: 20px;
    fill: var(--dark-white);
}
.review-star.filled{
    fill: var(--orange);
}