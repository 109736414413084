.manager-controls{
    display: flex;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    background: white;
    padding: 10px;
    height: 268px;
}
.manager-list{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 290px);
    border: 1px solid var(--dark-white);
}
.manager-list.draft .manager-item{
    border-left: 5px solid var(--orange);
}

.manager-list::-webkit-scrollbar{
    width: 7px;
}
.manager-item{
    padding: 20px;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-left: 5px solid var(--green);
    border-bottom: 1px solid var(--dark-white);
    cursor: pointer;
    transition: .5s;
}
.manager-item:hover{
    z-index: 2;
    transform: translateX(20px);
}
.manager-item:nth-child(2n){
    background: var(--light-white);
}
.filter-controls{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 10px;
    border-top: 1px solid var(--light-white);
    border-bottom: 1px solid var(--light-white);
}
.label-display{
    display: flex;
    padding: 5px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid var(--dark-white);
    gap: 5px;
}
.label-display:first-child{
    color: blue;
}
.label-display:nth-child(2){
    color: red;
}
.label-display:nth-child(3){
    color: purple;
}
.label-display:nth-child(4){
    color: rgb(0, 207, 0);
}
.label-display:nth-child(5){
    color: rgb(203, 203, 0);
}
.label-display:nth-child(6){
    color: rgb(255, 85, 0);
}
.label-value{
    font-size: 18px;
}
.label-value:nth-child(2){
    transform: scale(1.1);
}
.label-value:nth-child(3){
    transform: scale(1.2);
}
.label-value:nth-child(4){
    transform: scale(1.3);
}
.label-value:nth-child(5){
    transform: scale(1.4);
}

.structure-number{
    border: 1px solid var(--dark-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 10px;
    width: 30px;
    height: 30px;
}
.structure-number.primary{
    background: var(--light-white);
}

.collection-controls{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.collection-controls.locked::before{
    z-index: 2;
    content: "🔒";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 30px;
    background: rgba(255, 255, 255, 0.8);
}