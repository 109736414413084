@keyframes countdown-after{
    0% {
        transform: scale(20) rotate(0);
        border-radius: 20px;
    }
    25.001%{
        transform: scale(6) rotate(20deg);
        border-radius: 50%;
    }
    50.001%{
        transform: scale(0) rotate(-60deg);
        border-radius: 20px;
    }
    75.001%{
        transform: scale(10) rotate(80deg);
        border-radius: 50%;
    }
    90% {
        transform: scale(0) rotate(-200deg);
        border-radius: 20px;
    }
}
@keyframes countdown-text{
    0% {
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    20.001%{
        opacity: 1;
        transform: scale(1) rotate(10deg);
    }
    25.001%{
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    45.001%{
        opacity: 1;
        transform: scale(2.5) rotate(-30deg);
    }
    50.002%{
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    70.001%{
        opacity: 1;
        transform: scale(3.5) rotate(20deg);
    }
    75.002%{
        opacity: 1;
        transform: scale(0) rotate(0);
    }
    100% {
        opacity: 0;
        transform: scale(0) rotate(0);
    }
}

.countdown-label{
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.countdown{
    position: relative;
    z-index: 10;
    border-radius: 10px;
    color: black;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.countdown:after{
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(0);
    background: white;
    opacity: 1;
    animation: countdown-after 4s ease;
}
.countdown-text{
    opacity: 0;
    font-size: 170px;
    animation: countdown-text 4s linear;
}