.phone-viewer-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
    margin: 0px;
    position: relative;
    animation: reveal .2s ease-out;
    align-items: stretch;
}
.phone-viewer-row-container{
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
.phone-viewer-row{
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: calc(200% + 32px);
    height: calc(100% - 134px);
    top: 0;
    gap: 30px;
    transition: .25s;
}
.phone-viewer-row.left{
    left: 0;
}
.phone-viewer-row.right{
    left: calc(-100% - 30px);
}

.phone-viewer-row-item{
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100px;
    overflow: hidden;
    border-radius: 0 0 30px 30px;
}
.phone-viewer-row-item.margin{
    margin: 20px 20px 0 20px;
    width: calc(100% - 80px);
    border-radius: 20px;
}
.phone-viewer-row-item .content-field-gr{
    height: 100%;
}
.phone-viewer-row-item .answer-container{
    margin-bottom: 0;
}
.phone-viewer-row-item .controller-container{
    margin: 0;
}

