@media screen and (max-width: 799px) {
    .media-input.trimmer{
        padding-bottom: 140px;
    }
    .media-input-trimmer-container{
        bottom: 70px;
    }
}
@media screen and (min-width: 800px) {
    .media-input.trimmer{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .media-input-trimmer-container{
        bottom: 0px;
    }
}

.video-input{
    position: relative;
    margin: 0px;
    background: var(--light-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--grey-text);
    width: auto;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px dashed var(--mid-white);
    width: 100%;
    height: 170px;
    max-width: 100%;
    max-height: 170px;
    transition: .5s;
}
.video-input:hover{
    color: black;
    background: var(--mid-white);
    border: 1px dashed var(--dark-white);
}
.video-input-label{
    color: white;
    position: absolute;
    z-index: 2;
    width: 100%;
    border-radius: 10px;
    height: auto;
    transition: .5s;
    opacity: 0;
}
.video-input.active{
    border: none;
    width: auto;
    background: var(--mid-white);
}
.video-input.active:hover{
    border: none;
}
.video-input.active:hover .video-input-label{
    opacity: 1;
}

.video-input.active:hover .video-input-video{
    filter: brightness(.5);
}

.video-input-video{
    max-width: 100%;
    max-height: 170px;
    transition: .5s;
}

.media-input-video{
    max-width: calc(100vw);
    max-height: calc(100vh - 140px);
}

.media-input.trimmer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 140px);
    background: var(--pop-up-dark);
}
.media-input-trimmer-container{
    position: absolute;
    display: flex;
    flex-direction: row;
    background: white;
    padding: 20px;
    height: 39px;
    gap: 10px;
    width: calc(100% - 40px);
}
.media-input-trimmer{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    border: 1px solid var(--dark-white);
}
.media-input-trimmer-current{
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    top: 1px;
    height: 100%;
    background: black;
    height: 37px;
    width: 2px;
    transition: .2s linear;
}

.noUi-horizontal{
    height: 37px;
    border-radius: 10px;
    border: none;
    overflow: hidden;
    background: var(--light-white);
}
.noUI-target{
    background: none;
}
.noUi-handle {
    cursor: default;
    box-shadow: none;
}
.noUi-connect {
    background: var(--gradient);
    background-size: 300%;
    border-radius: 5px;
}
.noUi-horizontal .noUi-handle{
    z-index: 5;
    top: 0px;
    height: 37px;
    width: 18px;
    background: rgba(0, 0, 0, 0.4);
    border: none;
}
.noUi-horizontal .noUi-handle::after, .noUi-handle::before{
    display: none;
}
.noUi-horizontal .noUi-handle-lower{
    right: -18px;
}
.noUi-horizontal .noUi-handle-upper{
    right: 0;
}