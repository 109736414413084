:root{
    --control-bar-height: 40px;
    --control-bar-total-padding: 10px;
    --control-bar-total-height: calc(var(--control-bar-height) + var(--control-bar-total-padding));
    --question-height: 15vh;
    --question-total-padding: 40px;
    --correct-answer-total-margin: 40px;
    --question-total-height: calc(var(--question-height) + var(--question-total-padding));
    --viewport-height: calc(100vh - var(--control-bar-total-height));
    --viewport-width: 100vw;
}

@media screen and (max-width: 450px) {
    .correct-answer{
        flex-direction: column;
    }
}
@media screen and (min-width: 451px) {
    .correct-answer{
        flex-direction: row;
    }
}
@media screen and (min-width: 800px) {
}

@keyframes score-card{
    0% {
        transform: translateY(var(--viewport-height));
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes score-stage{
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(5%);

    }
    100%{
        transform: translateY(0);
    }
}
@keyframes slide{
    0% {
        transform: translateX(-400vw);
    }
    100%{
        transform: translateX(-50%);
    }
}
@keyframes swipe-out{
    0% {
        transform: translateX(0);
    }
    100%{
        transform: translateX(100vw);
    }
}
@keyframes swipe-in{
    0% {
        transform: translateX(-100vw);
    }
    100%{
        transform: translateX(0);
    }
}
@keyframes question-before{
    0% {
        transform: translateX(-50%) scaleY(0);
    }
    100%{
        transform: translateX(-50%) scaleY(1);
    }
}
@keyframes question-slide-up{
    0% {
        top: calc(var(--viewport-height) / 2);;
        transform: translateY(-50%);
    }
    40%{
        top: calc(var(--viewport-height) / 2);;
        transform: translateY(-50%);
    }
    100% {
        top: 0;
        transform: translateY(0%);
    }
}
@keyframes slide-up{
    0% {
        transform: translateY(var(--viewport-height));
    }
    40%{
        transform: translateY(var(--viewport-height));
    }
    100% {
        transform: translateY(0vh);
    }
}
@keyframes text-bubble{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes correct-answer {
    0% {
        transform: translateY(var(--viewport-height));
    }
    40%{
        transform: translateY(var(--viewport-height));
    }
    100% {
        transform: translateY(0vh);
    }
}
@keyframes photo-reveal{
    0% {
        transform: scale(2);
        filter: blur(18px);
    }
    10% {
        filter: blur(18px);
    }
    10.5% {
        filter: blur(16px);
    }
    20% {
        filter: blur(16px);
    }
    20.5% {
        filter: blur(14px);
    }
    30% {
        filter: blur(14px);
    }
    30.5% {
        filter: blur(12px);
    }
    40% {
        filter: blur(12px);
    }
    40.5% {
        filter: blur(10px);
    }
    50% {
        transform: scale(1);
        filter: blur(10px);
    }
    50.5% {
        filter: blur(8px);
    }
    60% {
        filter: blur(8px);
    }
    60.5% {
        filter: blur(6px);
    }
    70% {
        filter: blur(6px);
    }
    70.5% {
        filter: blur(4px);
    }
    80% {
        filter: blur(4px);
    }
    80.5% {
        filter: blur(2px);
    }
    90% {
        filter: blur(2px);
    }
    90.5% {
        filter: blur(0px);
    }
    100% {
        filter: blur(0px);
    }
}

@keyframes viewer-container{
    0% {
        background-position: 1000%;
    }
    100% {
        background-position: 0%;
    }
}

.viewer-message{
    color: black;
    font-weight: 500;
    font-size: clamp(1.1rem, 2.5vw, 2rem);
}
.viewer-text{
    font-weight: 500;
    font-size: clamp(1.1rem, 2.5vw, 3rem);
}
.viewer-text.s{
    font-size: clamp(1.1rem, 2.5vw, 1.5rem);
}
.viewer-text.xs{
    font-size: clamp(1rem, 2.5vw, 1.2rem);
}

.viewer-container{
    z-index: 0;
    position: relative;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    color: white;
    width: 100%;
    background: linear-gradient(90deg, var(--green), var(--yellow), var(--red), var(--yellow), var(--green));
    background-size: 10000px 100%;
    overflow: hidden;
    flex-grow: 1;
    animation: viewer-container 300s linear infinite;
}
.viewer-container::before{
    content: "";
    position: absolute;
    z-index: -1;
    background: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.content-field.score-viewer{
    background: none;
    animation: swipe-in 1s ease;
}

.photo-reveal{
    position: relative;
    height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    width: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    max-height: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    max-width: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    min-height: 120px;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 20px;
    background: black;
    overflow: hidden;
    transition: .5s;
}
.photo-blocker{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}
.photo-reveal.active{
    height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    width: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    max-height: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    max-width: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
}
.photo-reveal.active .photo-reveal-img{
    animation: photo-reveal 15s ease;
}
.photo-reveal-img{
    width: 100%;
    height: 100%;
    filter: blur(0px);
    object-fit: cover;
}
.photo-reveal-label{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 0 20px 20px;
    color: white;
}
.video-reveal{
    position: relative;
    opacity: 1;
    transition: .5s;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    max-width: calc(var(--viewport-width) - var(--correct-answer-total-margin));
}
.video-reveal.hidden{
    opacity: 0;
}
.video-reveal.blur .video-reveal-video{
    opacity: 1;
    filter: blur(17px);
}
.video-reveal-video{
    border-radius: 20px;
    max-height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    max-width: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    margin: auto 0;
    height: 100%;
    object-fit: contain;
}
.video-blocker{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}
.btn.video-play-btn{
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 5;
    border-radius: 20px;
    padding: 20px;
    flex-grow: 0;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
    color: black;
    box-shadow: var(--box-shadow);
}

.score-card-earned{
    text-align: center;
    padding: 5px;
    border-radius: 20px;
    width: 50px;
}
.score-card-earned.green{
    background: var(--green);
}
.score-card-earned.red{
    background: var(--red);
}
.score-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    opacity: 0;
}
.score-card.active{
    animation: score-card 2s ease;
    opacity: 1;
}
.stage-card{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    color: white;
    width: calc(30% - 40px);
    border-radius: 20px;
    padding: 20px;
    bottom: 0;
    transition: 2s;
}
.stage-card-img{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    filter: brightness(.5);
    top: 0;
    left: 0;
    object-fit: cover;
}
.stage-card .avatar{
    width: 50px;
    height: 50px;
}
.stage-card:first-child{
    transform: translateX(-50%) translateY(var(--viewport-height));
}
.stage-card:nth-child(2){
    transform: translateY(var(--viewport-height));
}
.stage-card:nth-child(3){
    transform: translateY(var(--viewport-height));
}
.stage-card.active:first-child{
    height: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%) translateY(0vh);
    z-index: 3;

}
.stage-card.active:nth-child(2){
    height: calc(70% - 40px);
    transform: translateY(0vh);
    right: 0;
    z-index: 2;
}
.stage-card.active:nth-child(3){
    height: calc(50% - 40px);
    transform: translateY(0vh);
    left: 0;
    z-index: 1;
}
.score-stage{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 800px;
    justify-content: center;
    margin-bottom: 30px;
    z-index: 2;
    height: calc(var(--viewport-height) * 0.4);
    animation: score-stage 5s ease infinite;
}
.score-board{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    gap: 20px;
    width: calc(100% - 20px);
    margin: 0 10px;
    max-width: 700px;
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 20%);
}
.score-board.small{
    max-height: calc(var(--viewport-height) * 0.3);
}
.score-board::-webkit-scrollbar{
    width: 0px;
}

.control-bar{
    position: relative;
    padding: calc(var(--control-bar-total-padding) / 2) var(--control-bar-total-padding);
    width: calc(100% - var(--control-bar-total-padding) * 2);
    height: var(--control-bar-height);
    max-height: var(--control-bar-height);
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.control-bar-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 120px;
}
.control-bar-row.right{
    justify-content: flex-end;
}
.control-bar-row.left{
    justify-content: flex-start;
}

.control-bar-avatars{
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    overflow: hidden;
    mask-image: linear-gradient(to right, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);
    gap: 5px;
}
.control-bar-avatars::-webkit-scrollbar{
    display: none;
}
.control-bar-avatar{
    position: relative;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--light-white);
    aspect-ratio: 1;
    transition: .5s;
}
.control-bar-avatar .icon{
    position: absolute;
    min-width: 12px;
    width: 12px;
    min-height: 12px;
    height: 12px;
    padding: 2px;
    z-index: 2;
    top: -2px;
    left: -2px;
}
.control-bar-avatar-img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.text-bubble{
    position: relative;
    background: white;
    display: flex;
    align-items: center;
    gap: 10px;
    color: black;
    padding: 20px;
    transform-origin: 50% calc(100% + 40px);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    margin-bottom: 35px;
    animation: text-bubble 3s ease-in-out infinite;
}
.text-bubble::after{
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    background: white;
    transform:  translate(-50%, 50%) rotate(45deg);
    width: 30px;
    height: 30px;
}

.question{
    z-index: 1;
    position: absolute;
    color: black;
    display: flex;
    flex-direction: column;
    font-family: inherit;
    font-weight: 500;
    font-size: clamp(1rem, 2.5vw, 4rem); 
    left: 0;
    top: calc(var(--viewport-height) / 2);
    height: var(--question-height);
    width: calc(100% - var(--question-total-padding) * 2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: translateY(-50%);
    padding: calc(var(--question-total-padding) / 2) var(--question-total-padding);
    z-index: 3;
}
.question-description{
    font-weight: 500;
    font-size: clamp(.9rem, 2.5vw, 2rem); 
    color: var(--grey-text);
}
.question.slide-up{
    transform: translateY(-0%);
    top: 0;
    animation: question-slide-up 2s ease;
}
.question.slide-up.before::before{
    animation: question-before .5s ease;
}
.question.slide-up::before{
    animation: unset;
}
.question::before{
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 300vw;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    animation: question-before .5s ease;
}
.question-content{
    width: calc(100% - 60px);
    margin: 30px;
    margin-top: calc(var(--question-total-height) + 30px);
    height: 100%;
    max-height: calc(var(--viewport-height) - var(--question-total-height) - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
}
.question-content.row{
    flex-direction: row;
}
.question-content.slide-up{
    animation: slide-up 3s ease;
}

.correct-answer{
    width: calc(100% - var(--correct-answer-total-margin));
    margin: calc(var(--correct-answer-total-margin) / 2);
    margin-top: calc(var(--question-total-height) + calc(var(--correct-answer-total-margin) / 2));
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-grow: 1;
    opacity: 1;
    animation: correct-answer 2s ease;
}
.correct-answer.relative{
    position: relative;
}
.correct-answer.center{
    justify-content: center;
    align-items: center;
}
.correct-answer.no-margin{
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}
.correct-answer.column{
    flex-direction: column;
}
.correct-answer.wrap{
    flex-wrap: wrap;
}
.correct-answer.static{
    opacity: 0;
    animation: none;
}

.correct-player-list{
    display: grid;
    gap: 20px;
    width: 100%;
    height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    max-height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    grid-template-columns: repeat(auto-fit, minmax(150px, 2fr));
    align-items: stretch;
    justify-items: stretch;
}

.correct-tag{
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: white;
    color: black;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}
.correct-tag::after{
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    background: white;
    transform:  translate(-50%, 50%) rotate(45deg);
    width: 20px;
    height: 20px;
}
.correct-tag-list{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 10px;
}

.correct-player{
    font-family: inherit;
    font-size: 30px;
    position: relative;
    border-radius: 20px;
    overflow: visible;
    width: auto;
    text-align: center;
    transition: 0s;
}
.correct-player.fixed-amount{
    flex-grow: 1;
    min-width: 150px;
    min-height: 150px;
    max-height: 50%;
    aspect-ratio: 1;
}

.correct-player-avatar{
    border-radius: 50%;
    width: 20%;
    aspect-ratio: 1;
}
.correct-player .icon{
    position: absolute;
    bottom: 10px;
    left: 10px;
    min-width: 40px;
    min-height: 40px;
    aspect-ratio: 1;
    margin: 0;
    border-radius: 50%;
    background: var(--green);
}
.correct-player-img{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: white;
    border-radius: 20px;
    filter: brightness(.3);
    transition: filter 1s;
}
.correct-player-label{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: .5s;
}

.correct-text{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    gap: 10px;
    padding: 10px;
    background: white;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    color: black;
    min-height: 50px;
    font-size: clamp(16px, 5vw, 38px);
    font-weight: 600;
    transition: .5s;
    animation: text-bubble 5s ease-in-out infinite;
}
.correct-text.fixed{
    position: absolute;
    top: calc(var(--correct-answer-total-margin) / 2);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    animation: none;
}
.correct-text .icon{
    min-width: 40px;
    min-height: 40px;
    margin: 0;
    border-radius: 50%;
    background: var(--green);
    color: white;
}
.correct-text.false .icon{
    background: var(--red);
    color: black;
}
.correct-icon{
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    color: white;
    background: var(--green);
    border-radius: 50%;
}

.carousel{
    position: absolute;
    height: var(--carouselheight);
    gap: 30px;    
}
.carousel-item{
    display: none;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.carousel-item.swipe-in{
    display: flex;
    animation: swipe-in .5s ease;
}
.carousel-item.swipe-out{
    display: flex;
    animation: swipe-out .5s ease;
}

.photo-answer{
    position: relative;
    background: var(--light-black);
    height: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    width: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    max-height: calc(var(--viewport-width) - var(--correct-answer-total-margin));
    max-width: calc(var(--viewport-height) - var(--question-total-height) - var(--correct-answer-total-margin));
    aspect-ratio: 1;
    border-radius: 20px;
}
.photo-answer-label{
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 40px);
    padding: 20px;
    padding-top: 50px;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: white;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
}
.photo-answer-img{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    background: white;
}



/* Phone viewer dynamics */

