@media screen and (max-width: 499px) {
  .footer-container{
    flex-direction: column;
  }
  .footer .icon{
    height: 40px;
    width: 80px;
  }
}
@media screen and (min-width: 500px) {
  .footer-container{
    flex-direction: row;
  }
  .footer .icon{
    height: 50px;
  }
}

.footer{
  z-index: 1;
  background: var(--mid-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.footer-container{
  color: var(--dark-white);
  display: flex;
  justify-content: space-between;
  gap: 20px;
  max-width: 800px;
  width: calc(100vw - 60px);
  height: auto;
  padding: 40px 30px;
}
.footer-item{
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-direction: column;
}
.footer-item.no-gap{
  gap: 5px;
}
.footer-link{
  cursor: pointer;
  background: none;
  font-size: 14px;
  color: var(--grey-text);
  text-decoration: none;
  margin-left: 3px;
  transition: .3s;
}
.footer .footer-link.active{
  color: white;
}
.footer .footer-link:hover{
  color: white;
}

.menu{
    justify-content: center;
    display: flex; 
    align-items: center;
    height: auto;
    width: 100%;
}