@media screen and (max-width: 450px) {
    .player-card.empty{
        display: none;
    }
}
@media screen and (min-width: 451px) {
    .player-card.empty{
        display: none ;
    }
}
@media screen and (min-width: 800px) {
    .player-card.empty{
        display: block;
    }
}

@keyframes question-introduction-info{
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
        transform: translateY(10vh);
    }
    30%{
        opacity: 1;
        transform: translateY(0);
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 0;
        transform: translateY(0);
    }
}
@keyframes question-introduction{
    0% {
        transform: translateY(7px) scale(0);
    }
    10%{
        transform: translateY(-7px) scale(1);
    }
    50%{
        transform: translateY(-7px) scale(.99);
    }
    90% {
        transform: translateY(7px) scale(1);
    }
    100% {
        transform: translateY(-7px) scale(0);
    }
}

@keyframes rotate{
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.question-introduction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    transform: scale(0);
    animation: question-introduction 7s ease;
}
.question-introduction-title{
    font-weight: 600;
    font-size: clamp(26px, 5vw, 40px);
}
.question-introduction-container{
    position: relative;
    display: flex;
    flex-direction: column;
    color: black;
}
.question-introduction-info{
    opacity: 0;
    animation: question-introduction-info 7s ease;
}
.content-field.question-introduction-backdrop{
    animation: reveal .5s ease-in;
    background: white;
}
.btn.settings{
    z-index: 1;
    flex-grow: 0;
    padding: 10px;
    background: white;
    border-radius: 50%;
    color: black;
}
.btn.settings .icon{
    transition: .5s;
}
.btn.settings:hover .icon{
    transform: scale(.9);
}

.card-list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    gap: 20px;
}

.avatar-list{
    display: flex;
    margin-right: 2px;
    padding-right: 15px;
    max-width: 40%;
}
.avatar-list .avatar{
    min-height: 40px;
    min-width: 40px;
    margin-right: -15px;
}

.group-step-list{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.player-list{
    margin: 0px;
    width: 100%;
    border-radius: 30px;
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    box-sizing: border-box;
}
.player-list.center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 800px;
}

.replay-card{
    display: flex;
    gap: 5px;
    border-radius: 20px;
    margin-top: 30px;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    background: white;
    color: black;
    box-shadow: var(--box-shadow);
    transition: .5s;
}
.replay-card:hover{
    transform: scale(1.05);
}
.replay-card:hover .icon.big{
    animation: rotate .5s linear infinite;
}
.replay-card .icon.big{
    animation: rotate 2s linear infinite;
}

.action-card{
    text-decoration: none;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: white;
    border: none;
    border-radius: 20px;
    min-width: 100px;
    max-width: 800px;
    min-height: 120px;
    width: 160px;
    height: 110px;
    text-align: center;
    align-items: center;
    overflow: hidden;
    transition: .5s;
    color: black;
    box-shadow: var(--box-shadow);
    padding: 20px;
}
.action-card-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.action-card.blur:hover{
    background: white;
}
.action-card.green .icon.badge{
    background: linear-gradient(to right, var(--green), rgb(1, 175, 1));
}
.action-card.black-white{
    filter: grayscale(1);
}
.action-card.fixed{
    width: unset;
}
.action-card.space-between{
    justify-content: space-between;
}

.action-card:hover{
    transform: scale(1.05);
}
.player-card{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    color: black;
    height: 50px;
    max-height: 50px;
    min-width: 200px;
    flex-grow: 1;
    transition: .5s;
}
.player-card.small{
    height: 30px;
    max-height: 30px;
}
.player-card.small .avatar{
    min-width: 35px;
    min-height: 35px;
}
.player-card.small .bg-text{
    padding: 5px;
}
.player-card.wide{
    width: calc(100% - 20px);
}
.player-card.empty{
    box-shadow: none;
    background: var(--mid-white);
}
.player-card.grey{
    opacity: .5;
}
.player-card.hover:hover{
    transform: scale(0.97);
}
.player-card .avatar{
    z-index: 1;
    min-width: 50px;
    min-height: 50px;
    margin-right: 10px;
    transition: .5s;
}
.player-card .icon.badge{
    z-index: 1;
    width: 30px;
    height: 30px;
    margin: 0;
    margin-right: 10px;
    transition: .5s;
}
.player-card .column{
    align-items: flex-start;
}
.player-card-badge{
    top: 10px;
    right: 10px;
    padding: 4px 7px;
    position: absolute;
    background: var(--gradient);
    background-size: 500%;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    animation: gradient-infinite 10s linear infinite;
}
.group-title{
    font-weight: 600;
    font-size: clamp(28px, 5vw, 48px);
}
.group-list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    flex-grow: 1;
}
.add-group-container{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    gap: 20px;
    width: 100%;
    height: 200px;
    overflow: hidden;
    transition: .5s;
}
.add-group-container:hover{
    transform: scale(0.99);
}
.add-group-container:hover .add-group-container-img{
    transform: scale(1.1);
}
.add-group-container-img{
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: .5s;
}
.add-group-container-label{
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    width: calc(100% - 40px);
    height: 100%;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
}
.group-badge{
    position: absolute;
    z-index: 2;
    left: 15px;
    top: 15px;
    width: 40px;
}
.group-settings{
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    gap: 10px;
    top: 30px;
    right: 30px;
}

.group-banner-container{
    position: relative;
    z-index: 1;
    background: var(--light-black);
    min-height: 150px;
    color: white;
    overflow: hidden;
    animation: reveal .5s ease-out;
    width: 100%;
    margin: 0 0;  
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.group-banner-container.no-grow{
    flex-grow: 0;
}
.group-banner-img{
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: brightness(.5);
    transition: .5s;
    z-index: -1;
}
.group-banner-img.dark{
    filter: brightness(.3);
}

.group-item{
    text-decoration: none;
    position: relative;
    background: white;
    color: white;
    display: flex;
    flex-direction: column;
    animation: reveal .5s ease-out;
    border-radius: 20px;

    overflow: hidden;
    transform: translate3d(0, 0, 0);
    transition: .5s;
}
.group-item.success{
    background: var(--green);
}

.group-item-label.center{
    text-align: center;
}

.group-item:hover .group-item-img{
    transform: scale(1.1);
}
.group-item-img{
    z-index: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 140px;
    max-height: 200px;
    object-fit: cover;
    transition: .5s;
    filter: brightness(.9);
}

.group-item-label{
    z-index: 1;
    padding: 20px;
    width: calc(100% - 40px);
    flex: 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    background: var(--light-white);
    color: black;
}
.group-new{
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 10px;
    width: fit-content;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    transform-origin: 50% calc(100% + 40px);
    background: var(--gradient);
    background-size: 300% 100%;
    border-radius: 20px;
    margin-bottom: -20px;
    color: white;
}

.group-item-label.fill{
    width: calc(100% - 40px);
    height: calc(100% - 40px);
}

