.slider-labels{
    position: relative;
    display: flex;
    color: var(--grey-text);
    height: 15px;
    margin-top: 10px;
    margin-right: 15px;
}
.slider-min-value{
    position: absolute;
    left: 0;
}
.slider-max-value{
    position: absolute;
    right: -15px;
}

.slider{
    width: 100%;
    height: 50px;
}

.customSlider {
    width: 100%;
    height: 30px;
    border-radius: 20px;
    border: 1px solid var(--mid-white);
    background: var(--light-white);
}

.customSlider-track{
    /* Top value to align your track to the center of your thumb */
    z-index: 0;
    top: -1px;
    /* thickness of the track */
    height: 30px;
    border: 1px solid var(--mid-white);
    border-radius: 20px;
   /* default color of your track */
    background: var(--light-white);
}
.customSliderDouble-track{
    /* Top value to align your track to the center of your thumb */
    top: 0px;
    /* thickness of the track */
    height: 20px;
    border-radius: 10px;
   /* default color of your track */
    background: var(--light-white);
}


.customSliderDouble-track.customSliderDouble-track-1, .customSlider-track.customSlider-track-0  {
    z-index: 1;
    /* color of the track before the thumb */
    background: var(--gradient);
    background-size: 500%;
    border: 1px solid var(--yellow);
    left: 0px;
    top: 0px;
    height: 28px;
    animation: gradient-infinite 10s linear infinite;
}

.customSlider-thumb,.customSlider-thumb-1{
    position: relative;
    text-align: center;
    z-index: 3;
    cursor: pointer;
    width: 30px;
    height: 30px;
    outline: none;
    white-space: nowrap;
    overflow: visible;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    font-size: 0;
    color: black;
}

.customSlider-thumb-0::after{
    left: 0px;
}   
.customSlider-thumb-1::after{
    left: 0px;
}   

.customSlider-mark {
    cursor: pointer;
    top: 6px;
    width: 1.5px;
    height: 8px;
    background-color: var(--dark-white);
}