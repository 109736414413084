@media screen and (max-width: 450px) {
    .description-row{
        flex-direction: column;
        gap: 30px;
    }
    .description-row-item{
        width: 100%;
    }
}
@media screen and (min-width: 451px) {
    .description-row{
        flex-direction: column;
        gap: 30px;
    }
    .description-row-item{
        width: 100%;
    }
}
@media screen and (min-width: 1000px) {
    .description-row{
        flex-direction: row;
        width: 100%;
        gap: 70px;
    }
    .description-row-item{
        width: 50%;
    }
}

.description-row-list{
    display: flex;
    flex-direction: column;
    gap: 70px;
    width: 100%;
}
.description-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.description-row-item{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.description-row-item.flex-start{
    align-items: flex-start;
}
.description-row-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 500px;
    gap: 10px;
}
.description-row-img{
    width: 300px;
    height: 300px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vw - 40px);
    object-fit: cover;
    border-radius: 20px;
}
.description-row-img.large{
    width: 400px;
    height: 400px;
}