@media screen and (max-width: 299px) {

}
@media screen and (max-width: 799px) {
    .imgchosen.square{
        min-width: 110px;
        min-height: 110px;
        max-width: 110px;
        max-height: 110px;
    }
}
@media screen and (min-width: 800px) {

}

@keyframes slide-in{
    0% {
        transform: translateX(200vw);
    }
    100%{
        transform: translateX(0);
    }
}
@keyframes slide-out{
    0% {
        transform: translateX(0);
    }
    100%{
        transform: translateX(-200vw);
    }
}


.content-container.intake{
    margin-top: 20vh;
    margin-bottom: 20vh;
    justify-content: space-between;
}
.intake-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
}
.intake-container-column{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: stretch;
}
.intake-container.slide-in{
    animation: slide-in .5s ease-out;
}
.intake-container.slide-out{
    animation: slide-out .5s ease-out;
    transform: translateX(-200vw);
}
.intake-loader{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-white);
    margin-bottom: 15px;
}

.intake-info{
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(to bottom, white 80%, transparent 100%);
}
.intake-question{
    margin: 10px;
    max-width: 90%;
}

.intake-control-next{
    display: flex;
    justify-content: center;
}
.intake-control{
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
}
.intake-control::after{
    z-index: -1;
    position: absolute;
    content: "";
    left: -20px;
    bottom: -20px;
    width: 100vw;
    height: 25vh;
    pointer-events: none;
    background: linear-gradient(to top, white 60%, transparent 100%);
}
.intake-control .btn-gr{
    height: 50px;
    max-width: 400px;
    border-radius: 20px;
    justify-content: center;
    margin: 0px;
    padding: 10px;
}
.btn.info{
    position: fixed;
    z-index: 3;
    top: 30px;
    right: 30px;
    background: white;
    padding: 10px;
    border-radius: 50px;
    flex: 0;
    margin: 0 auto;
    color: black;
}
.btn.info .icon{
    transition: .5s;
}
.btn.info:hover .icon{
    transform: rotate(180deg);
}

.intakeoption{
    font-family: inherit;
    font-size: 1.5em;
    position: relative;
    border: none;
    border-radius: 10px;
    flex-grow: 1;
    overflow: visible;
    cursor: pointer;
    min-width: 150px;
    min-height: 150px;
    height: auto;
    width: 20%;
    box-sizing: border-box;
    text-align: center;
    transition: 0s;
}

.intakeoption.chosen .intakeoptionimg{
    opacity: .5;
}
.intakeoption.chosen .avatar{
    opacity: .4;
}

.intakeoption:hover .intakeoptionimg{
    filter: brightness(.1);
}
.intakeoption.chosen{
    background: none;
}
.intakeoption.chosen:after{
    position: absolute;
    content: "";
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 15px;
    top: -5px;
    left: -5px;
    background: var(--gradient);
    background-size: 500%;
    animation: gradient-infinite 20s linear infinite;
    z-index: -1;
}

.intakeoptionimg{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: white;
    filter: brightness(.3);
    transition: filter 1s;
}
.intakeoptionlabel{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 10px;
    color: white;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: .5s;
}
.intakeoptionlabel .avatar{
    width: 80px;
    height: 80px;
}
.intakeoptionlist{
    position: relative;
    z-index: 1;
    border-radius: 10px;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    gap: 20px;
}

.img-input-field{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    gap: 5px;
    max-width: 200px;
}

