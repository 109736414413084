@media screen and (max-width: 299px) {
    .nb{
        bottom: 0;
        border-top: 1px solid var(--light-white);
    } 
    .nb-label{
        display: block;
    }
    .nb-row{
        display: none;
    }
    .nb-item{
        width: 66px;
        height: 47px;
        color: var(--darkerwhite);
    }
    .nb-logo{
        visibility: visible;
    }
    .nb-logo.brand{
        display: none;
    }
    .nb-text{
        font-size: 12px;
    }
    .nb-action-container{
        display: none;
    }
    .nb-menu{
        bottom: 89px;
        right: 20px;
        width: calc(100vw - 80px);
    }
    .nb-menu-btn{
        color: var(--darkerwhite);
    }
    .nb-overlay-backdrop{
        bottom: 70px;
    }
    .nb-overlay-exit{
        bottom: 0px;
    }
    .nb-filter{
        bottom: 89px;
        width: calc(100vw - 40px);
        transform-origin: bottom center;
    }
}
@media screen and (max-width: 799px) {
    .avatar.border::before{
        content: "";
        z-index: 1;
        border-radius: 50%;
        background: var(--darkerwhite);
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .5;
        transition: .3s;
    }
    .nb{
        bottom: 0;
        border-top: 1px solid var(--light-white);
    }
    .nb-container{
        justify-content: center;
    }
    .nb-item{
        width: 66px;
        height: 47px;
        justify-content: flex-end;
    }
    .nb-label{
        display: block;
    }
    .nb-item{
        color: var(--darkerwhite);
    }
    .nb-row{
        display: none;
    }
    .nb-logo{
        height: 26px;
        width: 26px;
        visibility: visible;
    }
    .nb-logo.brand{
        display: none;
    }
    .nb-text{
        display: none;
    }
    .nb-action-container{
        display: none;
    }
    .nb-menu{
        bottom: 89px;
        right: 20px;
        width: calc(100vw - 80px);
    }
    .nb-menu-btn{
        color: var(--darkerwhite);
    }
    .nb-overlay-backdrop{
        bottom: 70px;
    }
    .nb-overlay-exit{
        bottom: 0px;
    }
    .nb-filter{
        bottom: 89px;
        width: calc(100vw - 40px);
        transform-origin: bottom center;
    }
}
@media screen and (min-width: 800px) {
    .nb{
        top: 0;
        border-bottom: 1px solid var(--light-white);
    } 
    .nb-container{
        justify-content: space-between;
    }
    .nb-label{
        display: none;
    }
    .nb-item{
        color: var(--light-black);
    }
    .nb-row{
        display: flex;
    }
    .nb-logo{
        height: 0px;
        width: 0px;
        visibility: hidden;
        display: none;
    }
    .nb-logo.brand{
        visibility: visible;
        width: auto;
        display: block;
        transform: translateY(-1px);
    }
    .nb-text{
        display: block;
    }
    .nb-action-container{
        display: flex;
    }
    .nb-menu{
        top: 60px;
        right: 20px;
        width: 200px;
    }
    .nb-overlay-backdrop{
        top: 70px;
    }
    .nb-overlay-exit{
        top: 0px;
    }
    .nb-filter{
        top: 61px;
        width: 600px;
        transform-origin: top center;
    }
}

.nb{
    z-index: 10;
    height: 70px;
    width: 100vw;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: center;
    position: fixed;
    box-sizing: border-box;
    user-select: none;
}
.nb .text-xsmall{
    font-weight: 500;
}
.nb-container{
    position: relative;
    padding: 0 20px;
    width: 100%;
    max-width: 900px;
    display: flex;
    gap: 10vw;
    align-items: center;
}
.nb-text{
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    transition: .3s;
}
.nb-action-container{
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--light-black);
    transition: .5s;
}
.nb-action{
    transition: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    height: 30px;
    padding: 0 15px;
    border-radius: 20px;
    color: black;
}
.nb-action:hover,
.nb-action.active{
    background: var(--light-white);
}
.nb-action.left{
    margin-left: 20px;
}
.nb-row{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.nb-link:hover,
.nb-link.active{
    background: var(--light-white);
    color: black;

}
.nb-link{
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    transition: .5s;
    color: var(--grey-text);
}
.nb-action-container .icon{
    z-index: 1;
    padding: 10px;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    color: black;
}
.nb-icon{
    z-index: 1;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.nb-icon.active .nb-icon-background::after,
.nb-icon:hover .nb-icon-background::after{
    opacity: .6;
}
.nb-icon-background{
    width: 100%;
    height: 100%;
    background: var(--cgr);
    background-size: 1000% 100%;
    animation: cgr-dynamic 60s linear infinite;
}
.nb-icon-background::after{
    content: "";
    z-index: 1;
    position: absolute;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
    background: white;
    transition: .5s;
}
.nb-icon-list{
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    transition: .5s;
}
.nb-filter.active{
    height: fit-content;
    max-height: calc(100vh - 110px);
    transform: translateX(-50%) scale(1);
    opacity: 1;
}
.nb-filter{
    position: absolute;
    z-index: 2;
    left: 50%;
    opacity: 0;
    max-width: 600px;
    transform: translateX(-50%) scale(0);
    transition: .3s;
}
.nb-overlay-exit{
    left: 0;
    z-index: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.nb-overlay-backdrop{
    left: 0;
    z-index: -1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: var(--pop-up);
    animation: reveal .5s ease;
}
.nb-menu{
    position: absolute;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid var(--mid-white);
    box-shadow: var(--box-shadow);
    gap: 20px;
    transform-origin: top center;
    transform: translateX(100vw);
}
.nb-menu a{
    text-decoration: none;
    color: var(--grey-text);
}
.nb-menu a:hover{
    color: black;
}
.nb-menu.active{
    transform: translateX(0%);
}
.nb-menu-btn-icon{
    width: 20px;
    height: 20px;
}
.nb-menu-btn{
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 3px 3px 7px;
    font-size: 16px;
    font-weight: 500;
    height: 34px;
    width: 59px;
    gap: 5px;
    cursor: pointer;
    border-radius: 20px;
}
.nb-item:hover .nb-menu-btn,
.nb-item.active .nb-menu-btn{
    color: black;
}
.nb-item:hover .avatar.border .img,
.nb-item.active .avatar.border .img{
    border: 1px solid white;
}
.nb-item:hover .avatar:after,
.nb-item.active .avatar:after{
    opacity: 1;
}
.nb-item:hover .avatar:before,
.nb-item.active .avatar:before{
    opacity: 0;
}
.nb-label{
    font-size: 12px;
    font-weight: 500;
}
.nb-text.bold{
    font-weight: 900;
}
.nb-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 3px;
}
.nb-item:hover,
.nb-item.active{
    text-decoration: none;
    color: black;
}
.nb-item:hover .nb-label,
.nb-item.active .nb-label{
    color: black;
}
.avatar.border{
    z-index: 1;
    position: relative;
    border: 2px solid transparent;
    background: transparent;
    transition: .3s;
}
.avatar.border .img{
    border: 1px solid transparent;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
}
.avatar.border:after{
    content: "";
    z-index: -1;
    border-radius: 50%;
    background: var(--cgr);
    background-size: 300%;
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
    opacity: 0;
    transition: .3s;
    animation: gradient-infinite 30s linear infinite;
}

.nb-logo.brand{
    height: 35px; 
}

.nb-logo.user{
    height: 26px;
    width: 26px;
    z-index: 1;
    visibility: visible;
    display: block;
}
.nb-logo{
}
.nb-logo img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
