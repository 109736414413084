.loader{
    width: 70px;
    height: 70px;
    animation: load 2s ease infinite;
    align-self: center;
}
.load{
    width: 20px;
    height: 20px;
    animation: load 2s ease infinite;
}

@keyframes load{
    0%{
        transform: rotate(0);
    }
    100%{ 
        transform: rotate(360deg); 
    }
}