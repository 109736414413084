.selection-bar{
    background: var(--light-white);
    border-radius: 12px;
    display: flex;
    border: 1px solid var(--mid-white);
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    min-height: 34px;
    min-width: 30px;
    max-height: 34px;
    gap: 4px;
    padding: 4px;
    color: black;
}
.selection-bar.round{
    border-radius: 20px;
}
.selection-bar.round .selection-bar-option{
    border-radius: 20px;
}
.selection-bar::-webkit-scrollbar{
    display: none;
}
.selection-bar.white .selection-bar-option.active{
    background: white;
    color: black;
    border: 1px solid var(--mid-white);
    box-shadow: var(--box-shadow);
}
.selection-bar-option{
    position: relative;
    height: 34px;
    border-radius: 8px;
    padding: 8px 10px;
    margin: 0;
    font-weight: 500;
    font-family : inherit;
    font-size: 1em;
    flex: 1;
    background: none;
    border: 1px solid transparent;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .1s;
}
.selection-bar-option:hover{
    background: var(--mid-white);
    color: black;
}
.selection-bar-option.disabled{
    color: var(--dark-white);
}
.disabled-message{
    font-size: 10px;
    position: absolute;
    padding: 1px;
    bottom: -4px;
    color: var(--red);
    background: var(--light-white);
    border-radius: 5px;
    white-space: nowrap;
    transform: rotate(0deg);
}
.selection-bar-option.active{
    background: var(--gradient);
    background-size: 500%;
    border: 1px solid var(--yellow);
    box-shadow: var(--orange-box-shadow);
    animation: gradient-infinite 30s linear infinite;
    color: white;
}