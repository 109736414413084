@media screen and (max-width: 450px) {
    .hero-row{
        flex-direction: column;
    }
    .hero-row-item{
        width: 100%;
    }
    .hero-title{
        margin-top: 30vh;
        margin-bottom: 5px;
    }
    .hero-description{
        margin-bottom: 20px;
        font-size: 18px;
    }
    .hero-filter-ref{
        top: -40px;
    }
}
@media screen and (min-width: 451px) {
    .hero-row{
        flex-direction: column;
    }
    .hero-row-item{
        width: 100%;
    }
    .hero-title{
        margin-top: 30vh;
        margin-bottom: 10px;
    }
    .hero-description{
        margin-bottom: 20px;
        font-size: 18px;
    }
    .hero-tv{
        display: none;
    }
    .hero-filter-ref{
        top: -40px;
    }
}
@media screen and (min-width: 1000px) {
    .hero-row{
        gap: 30px;
        flex-direction: row;
    }
    .hero-row-item{
        width: 50%;
        position: relative;
    }
    .hero-title{
        margin-top: 100px;
        margin-bottom: 30px;
    }
    .hero-description{
        margin-bottom: 20px;
        font-size: 18px;
    }
    .hero-tv{
        display: unset;
    }
    .hero-filter-ref{
        top: -150px;
    }
}

.hero-banner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 2;
}

.hero-banner-video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: .9;
    mask-image: linear-gradient(to top, transparent 0%, black 100%);
}

.btn-gr.hero{
    height: 50px;
    padding: 20px;
    border: 1px solid var(--dark-white);
    box-shadow: var(--box-shadow);
    color: var(--dark-white);
    background: var(--light-white);
    transition: none;
}
.btn-gr.hero.active{
    color: white;
    background: var(--gradient);
    border: 1px solid var(--yellow);
    box-shadow: var(--orange-box-shadow);
    background-size: 300% 100%;
    animation: gradient-infinite 10s linear infinite;
}
.hero-filter-container{
    position: relative;
    padding: 0 20px;
    margin: 0 -20px;
    padding-top: 20px;
    height: 400px;

    pointer-events: initial;
    transition: .5s;
}
.hero-filter-container-blocker{
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.hero-filter-container.active{
    height: 450px;
    filter: blur(0);
    mask-image: none
}
.hero-filter-container.active .hero-filter-container-blocker{
    display: none;
}
.hero-filter-ref{
    position: absolute;
}
.hero-title{
    z-index: 1;
    font-weight: 500;
    line-height: 100%;
    overflow-wrap: normal;
    font-size: clamp(40px, 7vw, 80px); 
}
.hero-description{
    font-weight: 400;
    line-height: 24px;
}
.hero-column{
    color: white;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}
.hero-row{
    flex: 1;
    display: flex;
}
.hero-row-item{
    display: flex;
    flex-direction: column;
}
.hero-row-item.front{
    z-index: 2;
    max-width: 600px;
}
/*
.hero-row-item.front::before{
    z-index: -1;
    content: "";
    position: absolute;
    top: 59vh;
    left: -50%;
    width: 200vw;
    height: 100%;
    transform: rotate(4deg);
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 100px;
}
*/