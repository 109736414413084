.compare-table{
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 100px;
    flex-grow: 1;
    width: 100%;
    min-width: 600px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    background: white;
    color: black;
}
.compare-table-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid var(--mid-white);
    height: 60px;
    width: calc(100% - 40px);
    padding: 20px;
    transition: .5s;
}
.compare-table-row:last-child{
    border: none;
}
.compare-table-row:hover .compare-table-column{
    background: var(--light-white);
}
.compare-table-explanation{
    color: var(--grey-text);
}
.compare-table-subject{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    width: 40%;
    padding: 10px;
}
.compare-table-column{
    z-index: 1;
    font-size: 16px;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 20%;
    filter: grayscale(.5);
    transition: .5s;
}
.compare-table-column:first-child{
    width: 40%;
}
.compare-table-column:first-child .compare-table-row{
    align-items: flex-start;
}
.compare-table-column:nth-child(2){
    filter: grayscale(0);
}
.compare-table-column:nth-child(2)::before{
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    top: -10px;
    width: 100%;
    height: calc(100% + 20px);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 50px;
    background: white;
}
.compare-table-column.main{
    position: relative;
    border-radius: 20px 20px 0 0;
    background: var(--light-white);
    box-shadow: black 0 0 10px;
}
.compare-scroll-container{
    margin: 0 auto;
    width: 700px;
}
.compare-scroll-section{
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 60px 0;
    margin: 0 10px;
    mask-image: linear-gradient(to right, transparent, black 40px, black calc(100% - 40px), transparent);
}

.faq-label{
    padding: 30px;
    cursor: pointer;
    color: var(--grey-text);
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: .3s;
}
.faq-label:hover,
.faq.active .faq-label{
    color: black;
    background: var(--mid-white);
}
.faq.active .faq-label{
    padding: 30px 30px 0 30px;
    background: var(--mid-white);
}
.faq-label .icon{
    transition: .3s;
    transform: rotate(45deg);
}
.faq-label .icon.active{
    transform: rotate(0deg);
}
.faq-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10vh;
    max-width: 800px;
    margin: 100px 0;
}
.faq-section.small{
    margin: 0;
    gap: 40px;
    max-width: none;
}
.faq-content{
    height: 0px;
    overflow: hidden;
    padding: 0 30px;
    transition: .3s;
    background: var(--mid-white);
    color: var(--light-black);
}
.faq.active .faq-content{
    height: fit-content;
    padding: 10px 30px 30px 30px;
    opacity: 1;
}
.faq-section-container{
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    overflow: hidden;
    background: var(--light-white);
}
.faq{
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.articles-section{
    margin: 100px 0;
}
.home-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 150px 0;
    width: 100%;
    gap: 30px;
    z-index: 1;
}
.home-section.margin-sides{
    margin: 150px 30px;
    width: calc(100% - 60px);
}
.home-section.max-width{
    margin: 150px auto;
    max-width: 900px;
}
.home-section.compare-section{
    align-items: unset;
}
.home-section.gap{
    gap: 50px;
}


.explanation-phone{
    z-index: 1;
    background: rgb(250, 250, 250);
    height: 600px;
    width: 300px;
    margin-top: -90px;
    margin-bottom: -90px;
    transform: scale(0.7);
    border-radius: 40px;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3),inset 0 -2px 6px 0 rgba(10,37,64,0.35);
    transform-origin: center;
}
.explanation-phone .question{
    font-size: 18px;
}
.explanation-phone .intake-info{
    position: absolute;
    padding-top: 10px;
}
.explanation-phone textarea{
    font-size: 16px;
}
.explanation-phone .question-content{
    margin-top: calc(var(--question-total-height) + 58px);
    max-height: calc(var(--viewport-height) - var(--question-total-height));
}

.mock-phone{
    z-index: 1;
    background: white;
    border-radius: 40px;
    width: 300px;
    height: 600px;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3),inset 0 -2px 6px 0 rgba(10,37,64,0.35);
    transform-origin: center;
}
.mock-phone .avatar{
    min-width: 40px;
    min-height: 40px;
    margin-right: 7px;
}
.mock-phone .text-large{
    font-size: 28px;
}
.mock-phone .question{
    font-size: 18px;
}
.mock-phone .question-description{
    font-size: 12px;
}
.mock-phone .question-content{
    max-height: calc(var(--viewport-height) - var(--question-total-height) - 10px);
}
.mock-phone .photo-reveal.active{
    height: calc(var(--viewport-height) - var(--question-total-height) - 40px);
    animation: photo-reveal 5s linear;
}
.mock-phone .player-card{
    min-width: unset;
    height:40px;
}
.mock-phone .bg-text{
    padding: 7px;
}
.mock-phone .btn.next{
    height: 60px;
    width: 50px;
}
.mock-phone .answer-container{
    overflow-x: hidden;
}
.mock-phone .info-bar-progress{
    margin-right: 5px;
    font-size: 12px;
}
.mock-phone .correct-player,
.explanation-phone .correct-player{
    height: 100px;
    width: 100px;
    font-size: 12px;
}
.mock-phone .correct-player-avatar,
.explanation-phone .correct-player-avatar{
    max-height: 40px;
    max-width: 40px;
}
.mock-phone-content{
    position: relative;
    overflow: hidden;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 30px;
    margin: 10px;
    background: var(--light-white);
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
    animation: reveal .2s ease-out;
    align-items: stretch;
}
.mock-phone-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
    border-radius: 30px;
    overflow: hidden;
    padding: 0;
    position: relative;
    animation: reveal .2s ease-out;
    border: 1px solid var(--mid-white);
    align-items: stretch;
}
.mock-phone-container.white{
    background: white;
}
.mock-phone-container.padding{
    padding: 10px;
}
.mock-phone-option-list{
    position: relative;
    z-index: 1;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    height: 77px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    gap: 20px;
}
.mock-phone-option{
    font-family: inherit;
    font-size: 1.5em;
    position: relative;
    background: black;
    border: none;
    border-radius: 20px;
    flex-grow: 1;
    overflow: hidden;
    cursor: pointer;
    min-width: 150px;
    min-height: 150px;
    height: auto;
    width: 100%;
    flex-grow: 1;
    text-align: center;
    transition: 0s;
}
.mock-phone-img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mock-phone.explanation{
    right: -70px;
    position: absolute;
    transform: scale(.7);
    animation: mock-phone 10s ease infinite;
}

@keyframes mock-phone{
    0% {
        transform: scale(.7);
    }
    50% {
        transform: scale(.65) rotate(-1deg);
    }
    100% {
        transform: scale(.7);
    }
}