:root{
    --progression-step-dimension: 100px;
}

@media screen and (max-width: 799px) {
    .progression-step{
        width: 100%;
        height: 40px;
    }
    .progression-step-label{
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 8px 8px 15px;
    }
    .progression-step-list{
        flex-direction: column;
    }
    .progression-step-container{
        flex-direction: row;
        gap: 10px;
    }
    .progression-step-icon::after{
        width: 10px;
        height: 100%;
        left: 10px;
        bottom: calc(50% + 10px);
    }
    .progression-step:hover{
        transform: scale(0.99);
    }
}
@media screen and (min-width: 800px) {
    .progression-step{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 70px;
        width: calc(100%);
    }
    .progression-step-label{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 8px;
    }
    .progression-step-list{
        flex-direction: row;
    }
    .progression-step-container{
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
    .progression-step-icon{
        top: -35px;
    }
    .progression-step-icon::after{
        top: 10px;
        left: calc(-50% - 5px);
        width: calc(100% + 15px);
        height: 10px;
    }
    .progression-step-check{
        display: none;
    }
    .progression-step:hover{
        transform: scale(0.97);
    }
}

.progression-steps-container{
    user-select: none;
    z-index: 1;
    margin-top: 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: .5s;
}
.progression-step-list{
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    gap: 15px;
}
.progression-step-container{
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}
.progression-step{
    position: relative;
    display: flex;
    border-radius: 20px;
    background: var(--mid-white);
    transition: .5s;
}
.progression-step:hover{
    background: var(--mid-white);
}
.progression-step-label{
    border-radius: 18px;
    display: flex;
    margin: 2px;
    color: var(--grey-text);
    width: calc(100% - 20px);
    top: 0;
    left: 0;
}
.progression-step.active{
    background: white;
    color: black;
}
.progression-step-container.completed .progression-step,
.progression-step-container.completed .progression-step-icon{
    background: white;
}
.progression-step-container.active .progression-step-label{
    background: rgba(255, 255, 255, 0.9);
    color: black;
}
.progression-step-container.active .progression-step,
.progression-step-container.active .progression-step-icon{
    color: white;
    background: var(--cgr);
    background-size: 300%;
}

.progression-step-container.completed .progression-step-icon::after,
.progression-step-container.active .progression-step-icon::after{
    background: white;
}

.progression-step-icon{
    height: 20px;
    width: 20px;
    padding: 5px;
    border-radius: 50%;
    color: var(--grey-text);
    background: var(--mid-white);
}
.progression-step-icon::after{
    content: "";
    position: absolute;
    z-index: -1;
    opacity: 0.5;
    border-radius: 10px;
    background: var(--mid-white);
}
.progression-step-icon .icon{
    width: 20px;
    height: 20px;
}
.progression-step-icon.first::after{
    display: none;
}
.progression-step-check{
    width: 20px;
    height: 20px;
    background: var(--green);
    border-radius: 50%;
    color: white;
}