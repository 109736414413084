@media screen and (min-width: 799px) {
    .btn.back.responsive{
        top: 30px;
    }
}
@media screen and (min-width: 800px) {
    .btn.back.responsive{
        top: 100px;
    }
}

.btn.back{
    position: fixed;
    left: 30px;
    top: 30px;
    background: white;
    border-radius: 50%;
    padding: 10px;
    color: black;
    z-index: 3;
}
.btn.back.sticky{
    position: absolute;
}
.btn.back .icon{
    transition: .5s;
}
.btn.back:hover .icon{
    transform: translateX(-3px);
}
