@media screen and (max-width: 799px) {
    .cookie-consent{
        top: 20px;
    }
}
@media screen and (min-width: 800px) {
    .cookie-consent{
        bottom: 30px;
    }
}

.cookie-consent{
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    left: 20px;
    width: calc(100% - 70px);
    max-width: 500px;
    background: var(--mid-black);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--dark-black);
    color: white;
    backdrop-filter: blur(7px);
    padding: 10px 10px 10px 20px;
    border-radius: 20px;
}
.cookie-consent a{
    color: white;
}