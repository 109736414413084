.image-slider-slide{
    height: 300px;
    width: 100%;}
.image-slider-img{
    height: calc(100% + 4px);
    width: 100%;
    object-fit: cover;
}
.image-slider-container {
    display: inline-block;
    border-radius: 20px;
    border: none;
    overflow: hidden;
    margin-bottom: -20px;
}
.image-slider-arrow{
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    width: 30px;
    height: 100%;
    transform-origin: center center;
    position: absolute;
    border: none;
    border-radius: 20px;
    background: none;
    color: white;
    opacity: 0;
    transition: .5s;
}
.image-slider-arrow:hover{
    background: var(--pop-up);
}
.image-slider-arrow.left{
    left: 0px;
}
.image-slider-arrow.right{
    right: 0px;
}
.image-slider-container .slick-dots{
    bottom: 0;
    height: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    max-width: 150px;
    transition: .5s;
}
.image-slider-container:hover .slick-dots,
.image-slider-container:hover .image-slider-arrow{
    opacity: 1;
}
.image-slider-container .slick-dots li.slick-active button:before {
    color:white !important;
}
.image-slider-container .slick-dots li button:before {
    color:white !important;
}