@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,800;1,300;1,500;1,800&display=swap');

:root{
  --orange: #ff9b54;
  --yellow: #ff7f51;
  --red: #ce4257;
  --darkred: #720026;
  --darkerred: #4f000b;

  --box-shadow: rgba(0, 0, 0, .15) 0px 2px 8px;
  --box-shadow-dark: rgba(0, 0, 0, .3) 0px 2px 8px;
  --orange-box-shadow: rgba(255, 127, 81, .5) 0px 2px 10px;
  --box-shadow-inset: rgba(0, 0, 0, .5) 0px 2px 8px inset;
  --transparentblack: #00000093;
  --blur: rgba(255, 255, 255, 0.3);
  --pagebackground: #171717;

  --light-black: #373636;
  --mid-black: #1e1e1e;
  --dark-black: #121212;
  --light-white: #f1f1f1;
  --mid-white: #e1e1e1;
  --dark-white: #d3d3d3;
  --darkerwhite: #bebebe;

  --pop-up: #00000086;
  --pop-up-dark: #000000b9;
  --pop-up-white: #ffffff9c;
  --inputfields: white;
  --inputfield: #e1e1e1;
  --inputfieldhover: #d3d3d3;
  --grey-text: #7b7b7b;
  --green: rgb(130, 255, 144);
  --mid-green: rgb(98, 206, 110);
  --dark-green: rgb(81, 176, 92);

  --gradient: linear-gradient(90deg, var(--orange), var(--yellow), var(--red), var(--yellow), var(--orange));
  --cgr: linear-gradient(90deg, var(--red), var(--yellow), var(--orange), var(--green), var(--orange), var(--yellow), var(--red));
  --cgr-light: linear-gradient(45deg, var(--red), var(--yellow), white, var(--green));
  --gradientdown: linear-gradient(0deg, var(--orange), var(--yellow), var(--darkred), var(--darkerred));
  --horgradient: linear-gradient(180deg, var(--orange), var(--yellow), var(--darkred), var(--darkerred));
}

html{
  scroll-behavior: smooth;
  min-height: 100%;
  background: white;
}
::-webkit-scrollbar {
}
::-webkit-scrollbar:hover {
  width: auto; /* Show scrollbar on hover */
  height: auto;
  /* Add additional styling as desired */
}
::-webkit-scrollbar-track-piece{
  display: none;
}
::-webkit-scrollbar-track {
  display: none;
  background: black;
}
::-webkit-scrollbar-thumb {
  background: var(--dark-white); 
  border-radius: 4px;
}

@media screen and (max-width: 450px) {
  h1{
    font-size: 30px;
  }
  h2{
    font-size: 26px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h6{
    font-size: .7rem;
  }
  p{
    font-size: 16px;
  }
  .btn{
    font-size: 1rem;
  }
  .contentlabel.no-margin{
    width: calc(100% - 40px);
    margin: 20px;
  }
  .content-container{
    width: calc(100% - 40px);
    margin: 20px;
  }
  .content-container.min-height{
    min-height: calc(100vh - 110px);
    min-height: calc(100svh - 110px);
  }
  .contentlabel{
    width: 80%;
    padding: 20px;
  }
  .content-page{
    padding-top: 0px;
    padding-bottom: 70px;
  }
  .message-list{
    top: auto;
    width: calc(100% - 60px);
    bottom: 70px;
  }
  .row{
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 451px) {
  h1{
    font-size: 32px;
  }
  h2{
    font-size: 28px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h6{
    font-size: .7rem;
  }
  p{
    font-size: 16px;
  }
  .btn{
    font-size: 1rem;
  }
  .contentlabel.no-margin{
    width: calc(100% - 60px);
    margin: 30px;
  }
  .content-container{
    width: calc(100% - 60px);
    margin: 30px;
  }
  .content-container.min-height{
    min-height: calc(100vh - 130px);
    min-height: calc(100svh - 130px);
  }
  .row{
    display: flex;
    flex-direction: column;
  }
  .contentlabel{
    width: 80%;
    padding: 20px;
  }
  .content-page{
    padding-top: 0px;
    padding-bottom: 70px;
  }
  .message-list{
    top: auto;
    bottom: 70px;
  }
}
@media screen and (min-width: 800px) {
  h1{
    font-size: 32px;
  }
  h2{
    font-size: 28px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h6{
    font-size: .7rem;
  }
  p{
    font-size: 16px;
  }
  .btn{
    font-size: 1rem;
  }
  .contentlabel.no-margin{
    width: calc(100% - 80px);
    margin: 40px auto;
  }
  .content-container{
    width: calc(100% - 80px);
    margin: 40px auto;
  }
  .content-container.min-height{
    min-height: calc(100vh - 150px);
    min-height: calc(100svh - 150px);
  }
  .row{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .contentlabel{
    width: 70%;
    padding: 40px;
  }
  .content-page{
    padding-top: 70px;
    padding-bottom: 0px;
  }
  .message-list{
    bottom: auto;
    top: 70px;
  }
}

@keyframes gradient-infinite {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

@keyframes balls {
  0% {
    background-position: 0% 200%;
  }
  50% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 200%;
  }
}

@keyframes breathe {
  0% {
    opacity: 1;
  }
  50%{
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes questionmarks {
  0% {
    mask-position: 100%;
    mask-size: 100px;
  }
  25%{
    mask-position: 0%;
    mask-size: 100px;
  }
  50%{
    mask-position: 100%;
    mask-size: 500px;
  }
  75%{
    mask-position: 0%;
    mask-size: 500px;
  }
  100%{
    mask-position: 100%;
    mask-size: 100px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
  50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@keyframes cgr-dynamic{
  0% {
      background-position: 1000%;
  }
  100% {
      background-position: 0%;
  }
}

@keyframes message {
	0% {
    opacity: 0;
	}
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
	100% {
    opacity: 0;
	}
}

.index{
  position: relative;
  z-index: 0;
}

a{
  text-decoration: underline;
  color: black;
}
.agr{
  background: var(--gradient);
  background-size: 500%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
  animation: gradient-infinite 10s linear infinite;
}
.agr:hover{
  animation: gradient-infinite 2s linear infinite;
}

.avatar{
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

body{
  margin: 0;
  font-family: 'Rubik' ,'Helvetica Neue',
  sans-serif;
  overflow-wrap: break-word;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label{
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 500;
  flex-grow: 1;
}

ul, li, p, h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0px;
}

i{
  padding-right: 5px;
}
h1{
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: -2px;
}
h2{
  font-weight: 500;
}
h3{
  font-weight: 500;
}
h4{
  font-weight: 500;
}
h5{
  font-weight: 500;
}
h6{
  font-weight: 400;
}
p{
  line-height: 1.3;
  font-weight: 400;
}
.text-xxlarge{
  line-height: clamp(40px, 5vw, 62px);
  font-weight: 500;
  font-size: clamp(40px, 5vw, 62px);
}
.text-xlarge{
  font-weight: 500;
  font-size: clamp(40px, 5vw, 50px);;
}
.text-large{
  font-weight: 500;
  font-size: 32px;
}
.text-large.responsive{
  font-size: clamp(32px, 5vw, 62px);
}
.text-medium{
  font-weight: 500;
  font-size: 20px;
}
.text-medium.grey-text{
  font-weight: 400;
}
.text-small{
  font-weight: 500;
  font-size: 17px;
}
.text-xsmall{
  font-weight: 400;
  font-size: 15px;
}
.text-small.grey-text{
  font-weight: 400;
}
.text-xxsmall{
  font-weight: 400;
  font-size: 12px;
}

.bg-text{
  background: white;
  display: flex;
  align-items: center;
  text-align: center;
  color: black;
  padding: 10px;
  border-radius: 10px;
}
.bg-text.black{
  color: white;
  background: black;
}
.bg-text.red{
  color: white;
  background: var(--red);
}
.bg-text.green{
  color: black;
  background: var(--green);
}
.bg-text.small{
  padding: 2px 8px;
}

.light-grey-text{
  color: var(--light-white);
}
.grey-text{
  color: var(--grey-text);
}
.red-text{
  color: var(--red);
}
.green-text{
  color: var(--green);
}
.dark-green-text{
  color: var(--mid-green);
}
.cgr-text{
  background: var(--cgr);
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
}

.bold{
  font-weight: 900;
}
.narrow{
  font-weight: 500;
}


ul{
  list-style-type: none;
  display: flex; 
}

.auth-form{
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: calc(100% - 30px);
  max-width: 500px;
  padding: 15px;
  margin: 10px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 20px;
  text-align: left;
  animation: reveal .5s ease-out;
}   
.auth-form.auth-pop-up{
  margin: 0px;
  padding: 0px;
  min-width: 180px;
  width: calc(100vw - 100px);
  max-width: 400px;
  justify-content: space-between;
  box-shadow: none;
  border-radius: 0px;
}
.auth-form .step{
  height: 250px;
}

.btn{
  user-select: none;
  font-family : inherit;
  text-decoration: none;
  background: white;
  border: 1px solid var(--mid-white);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin: 0px;
  gap: 5px;
  color: var(--grey-text);
  font-weight: 500;
  font-size: 16px;
  flex-grow: 1;
  transition: .5s;
}
.btn:hover{
  border: 1px solid var(--dark-white);
  background: var(--light-white);
  color: var(--light-black);
}
.btn.small-padding{
  padding: 5px;
}
.btn.text{
  border-radius: 0;
  border: none;
  background: none;
  text-decoration: none;
  color: inherit;
  flex-grow: 0;
  padding: 5px 10px;
}
.btn.text.grey{
  color: var(--grey-text);
}
.btn.text:hover{
  text-decoration: underline;
}
.btn.round{
  border-radius: 20px;
}
.btn.padding{
  padding: 10px 15px;
}

.btn.small{
  padding: 10px;
}

.btn-gr{
  font-family : inherit;
  flex-grow: 1;
  background: var(--gradient);
  background-size: 500%;
  padding: 10px 16px;
  margin: 0px;
  border: none;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1em;
  animation: gradient-infinite 60s linear infinite;
  gap: 5px;
  transition: .5s;
}
.btn-gr:hover {
  animation: gradient-infinite 5s linear infinite;
}
.btn.white{
  background: white;
  border: none;
  color: black;
  flex-grow: 0;
}
.btn.white:hover{
  background: var(--dark-white);
}
.btn.black{
  background: var(--light-black);
  border: 1px solid black;
  color: white;
  display: flex;
  align-items: center;
}
.btn.black:hover{
  background: black;
}
.btn.green{
  background: var(--mid-green);
  border: 1px solid var(--dark-green);
  color: white;
}
.btn.green:hover{
  background: var(--dark-green);
}
.btn.no-grow{
  flex-grow: 0;
}

.contentgrower{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.content-page{
  background: white;
  background-size: cover;
  min-height: calc(100vh - 70px);
  width: 100%;
  align-items: center;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
}
.content-page.overflow{
  overflow: unset;
}
.content-page.full{
  padding: 0;
  min-height: 100vh;  
}
.content-page.max{
  max-height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  .content-page{
    min-height: calc(100svh - 70px);
  }
  .content-page.full{
    min-height: -webkit-fill-available;
  }
}

.content-section{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  animation: reveal 1s ease-out;
  flex-grow: 1;
  transition: .5s;
}
.content-section.border{
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid var(--mid-white);
}
.content-section-label{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 20px;
  margin: 5px 0;
  color: black;
}

.content-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 0;
  position: relative;
  animation: reveal .2s ease-out;
}
.content-container.center{
  text-align: center;
  align-items: center;
}
.content-container.flex-start{
  justify-content: flex-start;
}
.content-container.no-grow{
  flex: 0;
}
.content-container.not-relative{
  position: unset;
}
.content-container.max-width{
  max-width: 800px;
}
.content-container.max-width-narrow{
  max-width: 500px;
  margin: 30px auto;
}
.content-container.max-width-wide{
  max-width: 1000px;
}
.content-container.min-height-small{
  min-height: 50vh;
}
.content-container.min-height-medium{
  min-height: 70vh;
}
.content-container.no-margin{
  margin: 0;
  width: 100%;
}
.content-container.no-margin-vertical{
  margin-top: 0;
  margin-bottom: 0;
}
.content-container.min-height.no-margin{
  min-height: calc(100vh - 70px);
  min-height: calc(100svh - 70px);
}
.content-container.margin{
  margin: 30px auto;
}
.content-container.min-height.no-nb-{
  min-height: 100vh;
}

.contentlabel{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 7vh auto;
  min-height: calc(86vh - 70px);
  padding: 0;
  position: relative;
  animation: reveal .2s ease-out;
}
.contentlabel.small{
  margin: 20px;
  width: calc(100% - 40px);
  min-height: unset;
}
.contentlabel.static{
  animation: unset;
}
.contentlabel.fixed{
  min-height: 200px;
  margin: 0 auto;
  flex: 0;
}
.contentlabel.max{
  max-width: 800px;
}
.contentlabel.no-margin{
  min-height: 1vh;
}
.contentlabel.grow{
  flex: 0;
  flex-grow: 1;
}
.contentlabel.notrelative{
  position: initial;
}
.contentlabel.flex-start{
  justify-content: flex-start;
}
.contentlabel.center{
  text-align: center;
  align-items: center;
}
.contentlabel.flex{
  align-items: stretch;
}

.contentlabelimgcontent{
  position: relative;
  z-index: 1;
  top: 1;
  padding: 15px;
  flex-grow: 1;
  width: auto;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  background: none;
  color: white;
}

.container{
  width: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin:  0px;
  padding: 20px;
  background: white;
  border-radius: 20px;
  color: black;
}
.container.max{
  max-width: 600px;
}
.container.no-grow{
  flex-grow: 0;
}
.img-container{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  background: none;
  color: white;
  min-height: 200px;
  border-radius: 20px;
}
.img-container-label{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 20px;
  background: none;
  color: white;
}
.img-container-img{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  flex-grow: 1;
  transition: 1s;
  filter: brightness(.5);
  object-fit: cover;
}


.content-field{
  color: black;
  width: 100%;
  position: relative;
  margin: 0 0;  
  background: var(--light-white);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-field.white{
  color: black;
  background: white;
}

.content-field.cgr{
  background: var(--cgr);
  background-size: 200%;
  color: white;
}
.content-field.black{
  color: white;
  background: var(--mid-black);
  overflow: auto;
}
.content-field.dark-black{
  color: white;
  background: black;
}
.content-field.no-overflow{
  overflow: hidden;
}
.content-field.grey{
  z-index: 0;
  color: black;
  width: 100%;
  position: relative;
  margin: 0 0;  
  background: var(--inputfield);
}
.content-field.blur{
  z-index: 1;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(13px);
}
.content-field.cgr-dynamic{
  z-index: 0;
  color: black;
  background: linear-gradient(90deg, var(--green), var(--yellow), var(--red), var(--yellow), var(--green));
  background-size: 10000px 100%;
  overflow: hidden;
  animation: cgr-dynamic 300s linear infinite;
}
.content-field.cgr-dynamic::before{
  content: "";
  position: absolute;
  z-index: -1;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.content-field.banner{
  min-height: 200px;
  position: relative;
  background: var(--light-white);
  color: white;
  overflow: hidden;
}
.content-field.banner.max-height{
  max-height: 200px;
}
.content-field-banner-img{
  position: absolute;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  transform: scale(1.2);
  filter: brightness(.5);
  object-fit: cover;
}
.content-field-banner-img.sticky{
  top: 0;
}
.content-field-banner-label{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  background: none;
  color: white;

}

.banner-img-filler{
  position: absolute;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  transition: 1s;
  filter: brightness(.5);
  object-fit: cover;
}
.banner-img-label{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;

  background: none;
  color: white;
}

.column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.column.grow{
  height: 100%;
  flex-grow: 1;
  justify-content: space-between;
}
.column.wide{
  min-width: 100%;
}
.column.center{
  align-items: center;
}
.column.gap{
  gap: 20px;
}
.column.no-gap{
  gap: 0;
}

.dot{
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  align-self: center;
  background: var(--green);
}
.dot.red{
  background: var(--red);
}
.dot.orange{
  background: var(--orange);
}
.dot.grey{
  background: var(--grey-text);
}
.status{
  color: var(--grey-text);
  font-size: 12px;
  text-align: start;
  font-weight: 400;
}
.message-list{
  z-index: 12;
  transform: translateX(-50%);
  left: 50%;
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}
.message{
  display: flex;
  opacity: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: none;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(14px);
  border-radius: 20px;
  padding: 15px;
  transition: .5s;
  animation: message 3s ease;
  animation-iteration-count: 1;
}
.message.error{
  background: rgba(206, 66, 87, 0.7);
  color: white;
}
.message.info{
  background: rgba(30, 30, 30, 0.7);
  color: white;
}

.close-btn{
  background: none;
  border: none;
  color: inherit;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: .5s;
}
.close-btn:hover{
  transform: scale(.8);
}

.img{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  flex-grow: 1;
  transition: 1s;
}

textarea{
  font-family: inherit;
  font-size: 1em;
  padding: 10px 15px;
  background: var(--light-white);
  color: var(--grey-text);
  border: 1px solid var(--mid-white);
  border-radius: 10px;
  width: calc(100% - 30px);
  height: 90px;
  transition: .5s;
  resize: none;
}
textarea:focus, 
textarea:hover{
  outline: none;
  color: black;
  border: 1px solid var(--dark-white);
  background: var(--mid-white);
}

input{
  font-family: inherit;
  padding: 10px 15px;
  margin: 0;
  font-size: 1em;
  color: var(--grey-text);
  width: 100%;
  background: var(--light-white);
  box-sizing: border-box;
  border: 1px solid var(--mid-white);
  border-radius: 10px;
  flex-grow: 1;
  transition: .5s;
}
input:focus, 
input:hover{
  outline: none;
  color: black;
  border: 1px solid var(--dark-white);
  background: var(--mid-white);
}
input:disabled{
  background: var(--light-white);
}
input.margin{
  margin-bottom: 7px;
}
input[type="text"], input[type="password"], input[type="email"]{
  max-height: 40px;
}
input[type="file"]{
  display: none;
}
input[type="checkbox"]{
  width: auto;
  min-height: 17px;
  min-width: 17px;
  margin: 2px;
  flex-grow: 0;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill{
  border: none;
  -webkit-text-fill-color: var(--grey-text);
  box-shadow: 0 0 0px 1000px var(--inputfield) inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--inputfield) inset;
}


.icon{
  min-width: 20px;
  height: 20px;
}
.icon.black{
  color: black;
}
.icon.white{
  color: white;
}
.icon.big{
  height: 30px;
}
.icon.massive{
  height: 60px;
}
.icon.badge{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border-radius: 15px;
  background: var(--gradient);
  background-size: 200%;
  color: white;
}
.icon.round{
  background: var(--light-white);
  border-radius: 50%;
  padding: 5px;
}
.icon.wrong{
  width: 22px;
  height: 22px;
  background: var(--red);
  border-radius: 50%;
  color: white;
  padding: 4px;
}
.icon.correct{
  width: 22px;
  height: 22px;
  background: var(--green);
  border-radius: 50%;
  padding: 4px;
  color: white;
}

.row{
  gap: 5px;
}
.row.stay{
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.row.no-grow{
  justify-content: flex-start;
}
.row.grow{
  width: 100%;
}
.row.no-gap{
  gap: 0px;
}
.row.gap{
  gap: 10px
}
.row.center{
  justify-content: center;
  align-items: center;
}
.row.space-between{
  justify-content: space-between;
}
.row.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.row.align-flex-start{
  align-items: flex-start;
}
.row.wide{
  width: 100%;
}
.row.stretch{
  align-items: stretch;
}
.grow{
  flex: 1;
}
.start{
  align-items: flex-start;
}

.border-column{
  border-top: 1px solid var(--mid-white);
  padding: 15px 0;
  gap: 5px;
  display: flex;
  flex-direction: column;
}
.border-column:first-child{
  border: none;
  padding-top: 0;
}
.border-column:last-child{
  padding-bottom: 0;
}
.border-column.no-border{
  border: none;
}
.border-column.no-padding{
  padding: 0;
}
.border-column.center{
  text-align: center;
  align-items: center;
}

/*dynamics*/
.yellow{
  color: var(--yellow);
}

.center{
  text-align: center;
}
.text-align-left{
  text-align: left;
}

.round{
  border-radius: 30px;
}

.divider{
  width: 0px;
  height: 200%;
  border-left: 1px solid var(--mid-white);
}
.horizontal-divider{
  width: 100%;
  border-top: 1px solid var(--mid-white);
}
.vertical-divider{
  height: 100%;
  border-left: 1px solid var(--mid-white);
}

.overflow-x-scroll{
  overflow-y: hidden;
  overflow-x: scroll;
  text-overflow: clip;
  white-space: nowrap;
}
.overflow-x-scroll::-webkit-scrollbar{
  display: none;
}

.fill{
  height: 100%;
}

.wide{
  width: 100%;
}

.space-between{
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.no-grow{
  flex-grow: 0;
}
.no-text-decoration{
  text-decoration: none;
}
.no-text-decoration-hover{
  text-decoration: none;
}
.no-text-decoration-hover:hover{
  text-decoration: none;
}
.no-gap{
  gap: unset;
}
.gap{
  gap: 10px;
}
.large-gap{
  gap: 20px;
}

.box-shadow{
  box-shadow: var(--box-shadow);
}
.border{
  border: 1px solid var(--mid-white);
}
.margin-bottom{
  margin-bottom: 20px;
}
.margin-top{
  margin-top: 20px;
}
.no-padding{
  padding: 0px;
}

.align-left{
  text-align: left;
}