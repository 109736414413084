@media screen and (max-width: 799px) {
    .media-input-controls{
        top: unset;
        bottom: 0;
    }
    .reactEasyCrop_Container{
      top: 0px;
    }
  }
@media screen and (min-width: 800px) {
    .media-input-controls{
        bottom: unset;
        top: 0;
    }
    .reactEasyCrop_Container{
      top: 70px;
    }
}

.img-input{
    position: relative;
    margin: 0px;
    background-color: var(--light-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--grey-text);
    width: auto;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    flex-grow: 1;
    transition: .5s;
    border: 1px dashed var(--mid-white);
}
.img-input.rect{
    aspect-ratio: 16/9;
}
.img-input.square{
    aspect-ratio: 1;
    max-width: 200px;
    max-height: 200px;
}
.img-input.avatar{
    border-radius: 50%;
    height: 80px;
    width: 80px;
    font-size: 12px;
}
.img-input.active{
    background: var(--mid-white);
    border: 1px solid transparent;
}
.img-input.active:hover{
    border: 1px solid transparent;
}
.img-input.active:hover .img-input-img{
    filter: brightness(.5);
}
.img-input.active:hover .img-input-label{
    opacity: 1;
}
.img-input.intake{
  width: 120px;
  height: 120px;
}
.img-input:hover{
  color: var(--grey-text);
  border: 1px solid var(--dark-white);
}

.img-input-img{
    position: absolute;
    padding: 0;
    width: 100%;
    border-radius: 10px;
    height: auto;
    transition: .5s;
}
.img-input-label{
    color: white;
    position: absolute;
    z-index: 2;
    width: 100%;
    border-radius: 10px;
    height: auto;
    transition: .5s;
    opacity: 0;
}

.media-input{
    z-index: 11;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(7px);
    align-items: center;
}
.media-input-controls{
    position: absolute;
    width: auto;
    left: 0;
    width: calc(100% - 40px);
    height: 70px;
    margin: 0 auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: white;
}

/*react-easy-crop*/
.reactEasyCrop_Container {
    position: absolute;
    left: 0;
    right: 0;
    height: calc(100% - 70px);
    overflow: hidden;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .reactEasyCrop_Image,
  .reactEasyCrop_Video {
    will-change: transform; 
  }
  
  .reactEasyCrop_Contain {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .reactEasyCrop_Cover_Horizontal {
    width: 100%;
    height: auto;
  }
  .reactEasyCrop_Cover_Vertical {
    width: auto;
    height: 100%;
  }
  
  .reactEasyCrop_CropArea {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 35px;
    box-sizing: border-box;
    box-shadow: 0 0 0 9999em;
    color: var(--pop-up-dark);
    overflow: hidden;
  }
  .reactEasyCrop_CropArea::after{
    content: "";
    position: absolute;
  
    width: 500px;
    height: 100%;
    top: 0;
    left: 0;
  }
  .reactEasyCrop_CropAreaRound {
    border-radius: 50%;
  }
  
  .reactEasyCrop_CropAreaGrid::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.5);
    top: 0;
    bottom: 0;
    left: 33.33%;
    right: 33.33%;
    border-top: 0;
    border-bottom: 0;
  }
  
  .reactEasyCrop_CropAreaGrid::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.5);
    top: 33.33%;
    bottom: 33.33%;
    left: 0;
    right: 0;
    border-left: 0;
    border-right: 0;
  }