@media screen and (max-width: 799px) {
    .article-navigation{
        display: none;
    }
    .article-sections{
        margin-left: 0;
    }
    .article-row{
        margin-top: 0px;
    }
}
@media screen and (min-width: 800px) {
    .article-navigation{
        display: flex;
    }
    .article-sections{
        margin-left: 190px;
    }
    .article-row{
        justify-content: center;
        margin-top: 30px;
    }
}

.article-title{
    font-size: 40px;
}
.article-subtitle{
    font-size: 20px;
}

.article-row{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.article-list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 20px;
}
.article-list.small{
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin-top: 60px;
    margin-bottom: 20px;
}
.article-list-item{
    position: relative;
    z-index: 1;
    height: 270px;
    border-radius: 20px;
    overflow: hidden;
}
.article-list-item:hover .article-list-item-img{
    transform: scale(1.2);
}
.article-list-item-label{
    position: absolute;
    display: flex;
    z-index: 1;
    flex-direction: column-reverse;
    text-align: left;
    padding: 20px;
    color: white;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    border-radius: 20px;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
}
.article-list-item-img{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: .5s;
}
.article-list-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    color: var(--grey-text);
}
.article-list-section.disc{
    list-style-type: disc;
}
.article-navigation{
    z-index: 2;
    position: sticky;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    top: 110px;
    height: fit-content;
    gap: 7px;
    min-width: 160px;
    padding-left: 20px;
    border-left: 1px solid var(--light-white);
}
.article-navigation-link{
    text-decoration: none;
    color: var(--grey-text);
}
.article-navigation-link:hover{
    color: black;
    text-decoration: underline;
}
.article-sections{
    z-index: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 40px;
    width: 100%;
    max-width: 800px;
}
.article-section{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
}
.article-section.margin-top{
    margin-top: 40px;
}
.artice-section:first-child{
    margin-top: 20px;
}
.article-section:last-child{
    margin-bottom: 20px;
}
.article-banner{
    position: relative;
    width: 100%;
    height: 300px;
}
.article-banner-label{
    position: absolute;
    display: flex;
    z-index: 1;
    flex-direction: column-reverse;
    padding: 20px;
    color: white;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
}
.article-banner-img{
    width: 100%;
    height: 100%;
    filter: brightness(.7);
    object-fit: cover;
}

.content-field.top-overflow{
    margin-top: -30px;
    border-radius: 30px;
}

.column.call-to-action{
    margin-top: 40px;
}