@media screen and (max-width: 799px){
    .option-list{
        flex-direction: column;
        overflow-y: scroll;
        scroll-snap-type: y proximity;
    }
    .option.wide{
        min-width: 100%;
    }
    .img-option{
        min-height: calc(100vw - 40px);
    }
}
@media screen and (min-width: 800px){
    .answer-container{
        overflow-x: scroll;
    }
    .option-list{
        flex-direction: row;
    }
    .controller-container::-webkit-scrollbar{
        height: 0;
    }
    .option.wide{
        min-height: 100%;
    }
    .img-option{
        min-width: calc(100vh - 40px);
    }
}

@keyframes points{
    0% {
        transform: scale(1);
    }
    40%{
        transform: scale(.8);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes option{
    0% {
        transform: scale(.7);
    }
    50%{
        transform: scale(.5);
    }
    100% {
        transform: scale(.7);
    }
}

.info-bar{
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0px 20px 20px 20px;
    padding: 10px;
    overflow: hidden;
    background: white;
    border-radius: 20px;
    color: black;
    height: 50px;
    max-height: 50px;
    transition: .5s;
}
.info-bar .avatar{
    z-index: 1;
    min-width: 50px;
    min-height: 50px;
    margin-right: 10px;
    transition: .5s;
}
.info-bar.small{
    height: 30px
}
.info-bar.small .avatar{
    min-width: 35px;
    min-height: 35px;
}

.info-bar-progress{
    color: var(--grey-text);
    padding-right: 5px;
    margin-right: 10px;
    border-right: 1px solid var(--dark-white);
}
.info-bar-points{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    background: black;
    width: 70px;
    height: 30px;
    animation: points .5s ease;
}

.rate-slider-container{
    width: 100%;
    height: 100%;
    flex: 1;
}
.rate-slider{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}
.rate-slider-track{
    /* Top value to align your track to the center of your thumb */
    z-index: 0;
    top: 0px;
    /* thickness of the track */
    height: 100%;
    width: 100%;
    border-radius: 20px;
   /* default color of your track */
    background: var(--dark-white);
}
.rate-slider-track.rate-slider-track-0{
    z-index: 1;
    background: linear-gradient(to top, var(--red) 30%, var(--orange) 55%, var(--orange) 65%, var(--green) 90%);
    background-attachment: fixed;
}
.rate-slider-thumb{
    height: 40px;
    border-radius: 20px;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: none;
}

.option-list{
    position: relative;
    z-index: 1;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    height: 77px;
    display: flex;
    border-radius: 20px;
    gap: 10px;
}
.option-list::-webkit-scrollbar{
    height: 5px;
    border-radius: 10px;
    background: none;
}
.option-list.stay{
    flex-direction: column;
}

.dummy-container{
    color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    pointer-events: all;
}

.controller-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
    margin: 20px 20px 0 20px;
    border-radius: 20px;
    overflow: hidden;
    padding: 0;
    position: relative;
    animation: reveal .2s ease-out;
    align-items: stretch;
}

.answer-container{
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.option{
    font-family: inherit;
    font-size: 1.5em;
    position: relative;
    background: black;
    border: none;
    border-radius: 20px;
    flex-grow: 1;
    overflow: hidden;
    cursor: pointer;
    min-width: 150px;
    min-height: 150px;
    height: auto;
    width: 20%;
    flex-grow: 1;
    text-align: center;
    transition: 0s;
}
.option.disabled{
    background: white;
}
.option-label{
    position: absolute;
    padding: 10px;
    z-index: 1;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    text-align: center;
    transition: .5s;
    overflow: hidden;
}
.option-label.disabled{
    color: var(--dark-white);
    animation: option 2s ease infinite;
}
.img-option{
    position: relative;
    padding: 0;
    border: none;
    border-radius: 20px;
    overflow: visible;
    text-align: center;
    background: var(--light-black);
    flex-grow: 1;
}
.img-option-img{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    background: white;
}

.player-option{
    font-family: inherit;
    position: relative;
    border: none;
    border-radius: 20px;
    overflow: visible;
    min-width: 150px;
    min-height: 150px;
    text-align: center;
    flex: 1;
}
.player-option.draw{
    min-height: 100px;
    flex: 0;
}
.player-option-list{
    position: relative;
    z-index: 1;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    height: 77px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    border-radius: 20px;
    gap: 10px;
}
.player-option-list::-webkit-scrollbar{
    height: 5px;
    border-radius: 10px;
    background: none;
}

.player-option-img{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: white;
    filter: brightness(.3);
    transition: filter 1s;
}
.player-option-img.draw:first-child{
    width: 50%;
    border-radius: 20px 0 0 20px;
}
.player-option-img.draw:nth-child(2){
    width: 50%;
    margin-left: 50%;
    border-radius: 0 20px 20px 0;
    right: 0;
}

.player-option-label{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 20px;
    color: white;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: .5s;
}

.result{
    z-index: 0;
    padding: 20px;
    color: white;
}
.result.wrong::after{
    background: var(--red);
}
.result.correct::after{
    background: var(--green);
}
.result.slow::after{
    background: var(--yellow);
}
.result::after{
    z-index: -1;
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    width: 100vw;
    height: 150vh;
    background: var(--red);
}
.result-icon{
    width: 100px;
}

.btn.host-control{
    z-index: 1;
    color: black;
    background: white;
    border-radius: 20px;
    border: none;
    width: 40px;
}
.btn.host-control.disabled{
    background: var(--light-white);
    color: var(--grey-text);
}
.btn.host-control:hover{
    background: var(--dark-white);
}
.host-controls{
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    width: calc(100% - 40px);
    flex-grow: 0;
    gap: 10px;
}
.host-controls .selection-bar{
    border: none;
    padding: 5px;
    flex-grow: 1;
    background: white;
}