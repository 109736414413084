.avatar{
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0px solid white;
    position: relative;
    overflow: hidden;
}

.avatar.border{
    overflow: visible;
}

.avatar.img {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.avatar.big{
    min-width: 70px;
    min-height: 70px;
    margin: 0px;
    overflow: hidden;
}

.avatar.medium{
    min-width: 40px;
    min-height: 40px;
    margin: 0px;
    overflow: hidden;
}