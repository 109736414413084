.stepper{
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
.step{
    position: relative;
    display: flex;
    height: 300px;
    flex-direction: column;
}
.btn.step-btn{
    position: absolute;
    left: 0;
    padding: 5px;
}
.btn.step-btn.right{
    left: unset;
    right: 0;
}
.step.last{
    padding-bottom: 0;
}
.step-label{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0px;
    transition: .5s;
}
.step-title{
    font-weight: 500;
    font-size: 20px
}
.step-content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    justify-content: space-between;
    margin-top: 10px;
    transition: .5s;
}
.step.last .step-content{
    margin-left: 0;
}
.step.complete .step-index{
    color: white;
    background: var(--light-black);
}
.step.complete .step-index::after{
    background: var(--light-black);
}
.step.complete .step-label{
    color: black;
}
.step.active .step-label{
    color: black;
}
.step.active .step-index{
    background: var(--yellow);
}
.step-label:hover{
    color: black;
}

.stepper-dots{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
    justify-content: center;
}
.step-dot{
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: var(--dark-white);
}
.step-dot.active{
    background: var(--yellow);
}

.step-error{
    color: var(--red);
}